// Name:            Position
// Description:     Utilities to position content
//
// Component:       `uk-position-absolute`
//                  `uk-position-relative`
//                  `uk-position-z-index`
//                  `uk-position-top`
//                  `uk-position-bottom`
//                  `uk-position-left`
//                  `uk-position-right`
//                  `uk-position-top-left`
//                  `uk-position-top-center`
//                  `uk-position-top-right`
//                  `uk-position-bottom-left`
//                  `uk-position-bottom-center`
//                  `uk-position-bottom-right`
//                  `uk-position-center`
//                  `uk-position-center-left`
//                  `uk-position-center-right`
//                  `uk-position-cover`
//
// Modifiers:       `uk-position-small`
//                  `uk-position-medium`
//                  `uk-position-large`
//
// ========================================================================

// Variables
// ========================================================================

$position-small-margin: $global-small-gutter !default;
$position-medium-margin: $global-gutter !default;
$position-large-margin: $global-gutter !default;
$position-large-margin-l: 50px !default;

/* ========================================================================
   Component: Position
 ========================================================================== */

/* Directions
 ========================================================================== */

/*
 * 1. Prevent content overflow if `max-width: 100%` is used inside position container.
 */

[class*="uk-position-top"],
[class*="uk-position-bottom"],
[class*="uk-position-left"],
[class*="uk-position-right"],
[class*="uk-position-center"] {
    position: absolute !important;
    /* 1 */
    max-width: 100%;
}

/* Edges
 ========================================================================== */

/* Don't use `width: 100%` because it is wrong if the parent has padding. */
.uk-position-top {
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
}

.uk-position-bottom {
    margin: auto;
    bottom: 0;
    left: 0;
    right: 0;
}

.uk-position-left {
    top: 0;
    bottom: 0;
    left: 0;
}

.uk-position-right {
    top: 0;
    bottom: 0;
    right: 0;
}

/* Corners
 ========================================================================== */

.uk-position-top-left {
    top: 0;
    left: 0;
}

.uk-position-top-right {
    top: 0;
    right: 0;
}

.uk-position-bottom-left {
    bottom: 0;
    left: 0;
}

.uk-position-bottom-right {
    bottom: 0;
    right: 0;
}

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 */

.uk-position-center {
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    /* 1 */
    // width: max-content;
    max-width: 100%;
    box-sizing: border-box;
}

/* Vertical */
[class*="uk-position-center-left"],
[class*="uk-position-center-right"] {
    top: 50%;
    transform: translateY(-50%);
}

.uk-position-center-left {
    left: 0;
}
.uk-position-center-right {
    right: 0;
}

.uk-position-center-left-out {
    right: 100%;
    width: max-content;
}

.uk-position-center-right-out {
    left: 100%;
    width: max-content;
}

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center {
    left: 50%;
    transform: translateX(-50%);
    /* 1 */
    width: max-content;
    max-width: 100%;
    box-sizing: border-box;
}

.uk-position-top-center {
    top: 0;
}
.uk-position-bottom-center {
    bottom: 0;
}

/* Cover
 ========================================================================== */

.uk-position-cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

/* Utility
 ========================================================================== */

.uk-position-relative {
    position: relative !important;
}

.uk-position-absolute {
    position: absolute !important;
}

.uk-position-fixed {
    position: fixed !important;
}

.uk-position-z-index {
    z-index: 1;
}

/* Margin modifier
 ========================================================================== */

/*
 * Small
 */

.uk-position-small {
    max-width: unquote("calc(100% - (#{$position-small-margin} * 2))");
    margin: $position-small-margin;
    margin-bottom: calc(env(safe-area-inset-bottom, 0) * 0.5 + $position-small-margin) !important;
}

.uk-position-small.uk-position-center {
    transform: translate(-50%, -50%) translate(-$position-small-margin, (-$position-small-margin));
}
.uk-position-bottom-footered {
    bottom: calc(8.5vh + calc(env(safe-area-inset-bottom, 0) * 0.5));
    z-index: 10000;
    width: -webkit-fill-available;
}
.uk-position-small[class*="uk-position-center-left"],
.uk-position-small[class*="uk-position-center-right"] {
    transform: translateY(-50%) translateY(-$position-small-margin);
}

.uk-position-small.uk-position-top-center,
.uk-position-small.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-$position-small-margin);
}

/*
 * Medium
 */

.uk-position-medium {
    max-width: unquote("calc(100% - (#{$position-medium-margin} * 2))");
    margin: $position-medium-margin;
}

.uk-position-medium.uk-position-center {
    transform: translate(-50%, -50%) translate(-$position-medium-margin, (-$position-medium-margin));
}

.uk-position-medium[class*="uk-position-center-left"],
.uk-position-medium[class*="uk-position-center-right"] {
    transform: translateY(-50%) translateY(-$position-medium-margin);
}

.uk-position-medium.uk-position-top-center,
.uk-position-medium.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-$position-medium-margin);
}

/*
 * Large
 */

.uk-position-large {
    max-width: unquote("calc(100% - (#{$position-large-margin} * 2))");
    margin: $position-large-margin;
}

.uk-position-large.uk-position-center {
    transform: translate(-50%, -50%) translate(-$position-large-margin, (-$position-large-margin));
}

.uk-position-large[class*="uk-position-center-left"],
.uk-position-large[class*="uk-position-center-right"] {
    transform: translateY(-50%) translateY(-$position-large-margin);
}

.uk-position-large.uk-position-top-center,
.uk-position-large.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-$position-large-margin);
}

/* Desktop and bigger */
@media (min-width: $breakpoint-large) {
    .uk-position-large {
        max-width: unquote("calc(100% - (#{$position-large-margin-l} * 2))");
        margin: $position-large-margin-l;
    }

    .uk-position-large.uk-position-center {
        transform: translate(-50%, -50%) translate(-$position-large-margin-l, (-$position-large-margin-l));
    }

    .uk-position-large[class*="uk-position-center-left"],
    .uk-position-large[class*="uk-position-center-right"] {
        transform: translateY(-50%) translateY(-$position-large-margin-l);
    }

    .uk-position-large.uk-position-top-center,
    .uk-position-large.uk-position-bottom-center {
        transform: translateX(-50%) translateX(-$position-large-margin-l);
    }
}

// Hooks
// ========================================================================

@if (mixin-exists(hook-position-misc)) {
    @include hook-position-misc();
}

// @mixin hook-position-misc(){}
