// Name:            Height
// Description:     Utilities for heights
//
// Component:       `uk-height-*`
//
// ========================================================================

// Variables
// ========================================================================

$height-small-height: 150px !default;
$height-medium-height: 300px !default;
$height-large-height: 450px !default;
$height-xlarge-height: 600px !default;
$height-xxlarge-height: 750px !default;

/* ========================================================================
   Component: Height
 ========================================================================== */

[class*="uk-height"] {
    box-sizing: border-box;
}

/*
 * Only works if parent element has a height set
 */

.uk-height-1-1 {
    height: 100%;
}

/*
 * Useful to create image teasers
 */

.uk-height-viewport {
    min-height: 100vh;
}

/*
 * Pixel
 * Useful for `overflow: auto`
 */

.uk-height-small {
    height: $height-small-height;
}
.uk-height-medium {
    height: $height-medium-height;
}
.uk-height-large {
    height: $height-large-height;
}
.uk-height-xlarge {
    height: $height-xlarge-height;
}
.uk-height-xxlarge {
    height: $height-xxlarge-height;
}

.uk-height-max-small {
    max-height: $height-small-height;
}
.uk-height-max-medium {
    max-height: $height-medium-height;
}
.uk-height-max-large {
    max-height: $height-large-height;
}
.uk-height-max-xlarge {
    max-height: $height-xlarge-height;
}
.uk-height-max-xxlarge {
    max-height: $height-xxlarge-height;
}

.uk-height-min-small {
    min-height: $height-small-height;
}

.uk-height-min-medium {
    min-height: $height-medium-height;
}

.uk-height-min-large {
    min-height: $height-large-height;
}

.uk-height-min-xlarge {
    min-height: $height-xlarge-height;
}

.uk-height-min-xxlarge {
    min-height: $height-xxlarge-height;
}

.uk-height-max-modal {
    max-height: calc(100dvh - 60px);
}

// Hooks
// ========================================================================

@if (mixin-exists(hook-height-misc)) {
    @include hook-height-misc();
}

// @mixin hook-height-misc(){}
