// Variables
// ========================================================================

$padding-padding: $global-gutter !default;
$padding-padding-l: $global-medium-gutter !default;
$padding-small-padding: $global-small-gutter !default;
$padding-large-padding: $global-medium-gutter !default;
$padding-large-padding-l: $global-large-gutter !default;
$padding-large-padding-xl: $global-extra-large-gutter !default;

/* ========================================================================
   Component: Padding
 ========================================================================== */

.uk-padding {
    padding: $padding-padding;
}

/* Desktop and bigger */
@media (min-width: $breakpoint-large) {
    .uk-padding {
        padding: $padding-padding-l;
    }
}

/* Extra Small
 ========================================================================== */

.uk-padding-xsmall {
    padding: $padding-xsmall-padding;
}

.uk-padding-xsmall-top {
    padding-top: $padding-xsmall-padding;
}

.uk-padding-xsmall-bottom {
    padding-bottom: $padding-xsmall-padding;
}

.uk-padding-xsmall-left {
    padding-left: $padding-xsmall-padding;
}

.uk-padding-xsmall-right {
    padding-right: $padding-xsmall-padding;
}

/* Small
 ========================================================================== */

.uk-padding-small {
    padding: $padding-small-padding;
}

.uk-padding-small-top {
    padding-top: $padding-small-padding;
}

.uk-padding-small-bottom {
    padding-bottom: $padding-small-padding;
}

.uk-padding-small-bottom-safe-area {
    padding-bottom: calc(env(safe-area-inset-bottom, 0) * 0.5 + $padding-small-padding) !important;
}

.uk-padding-large-bottom-safe-area {
    padding-bottom: calc(env(safe-area-inset-bottom, 0) * 1 + $padding-large-padding-l) !important;
}

.uk-padding-medium-bottom-safe-area {
    padding-bottom: calc(env(safe-area-inset-bottom, 0) * 1 + $padding-large-padding) !important;
}

.uk-padding-small-top-safe-area {
    padding-top: calc(env(safe-area-inset-top, 0) * 1 + $padding-small-padding) !important;
}

.uk-padding-top-safe-area {
    padding-top: calc(env(safe-area-inset-top, 0) * 1 + $padding-medium-padding) !important;
}

.uk-padding-large-top-safe-area {
    padding-top: calc(env(safe-area-inset-top, 0) * 1 + $padding-large-padding-l) !important;
}

.uk-padding-xlarge-top-safe-area {
    padding-top: calc(env(safe-area-inset-top, 0) * 1 + $padding-large-padding-xl) !important;
}

.uk-padding-small-left {
    padding-left: $padding-small-padding;
}

.uk-padding-small-right {
    padding-right: $padding-small-padding;
}

/* Medium
 ========================================================================== */

.uk-padding-medium {
    padding: $padding-medium-padding;
}

.uk-padding-small-medium {
    padding-top: $padding-medium-padding;
}

.uk-padding-small-medium {
    padding-bottom: $padding-medium-padding;
}

.uk-padding-small-medium {
    padding-left: $padding-medium-padding;
}

.uk-padding-small-medium {
    padding-right: $padding-medium-padding;
}

/* Large
 ========================================================================== */

.uk-padding-large {
    padding: $padding-large-padding-l;
}

.uk-padding-large-top {
    padding-top: $padding-large-padding-l;
}

.uk-padding-large-bottom {
    padding-bottom: $padding-large-padding-l;
}

.uk-padding-large-left {
    padding-left: $padding-large-padding-l;
}

.uk-padding-large-right {
    padding-right: $padding-large-padding-l;
}

/* Extra Large
 ========================================================================== */

.uk-padding-xlarge {
    padding: $padding-large-padding-xl;
}

.uk-padding-xlarge-top {
    padding-top: $padding-large-padding-xl;
}

.uk-padding-xlarge-bottom {
    padding-bottom: $padding-large-padding-xl;
}

.uk-padding-xlarge-left {
    padding-left: $padding-large-padding-xl;
}

.uk-padding-xlarge-right {
    padding-right: $padding-large-padding-xl;
}

/* Remove
 ========================================================================== */

.uk-padding-remove {
    padding: 0 !important;
}
.uk-padding-remove-top {
    padding-top: 0 !important;
}
.uk-padding-remove-bottom {
    padding-bottom: 0 !important;
}
.uk-padding-remove-left {
    padding-left: 0 !important;
}
.uk-padding-remove-right {
    padding-right: 0 !important;
}

.uk-padding-remove-vertical {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.uk-padding-remove-horizontal {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.uk-transform-remove {
    transform: unset !important;
}

// Hooks
// ========================================================================

@if (mixin-exists(hook-padding-misc)) {
    @include hook-padding-misc();
}

// @mixin hook-padding-misc(){}
