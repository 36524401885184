// Name:            Modal
// Description:     Component to create modal dialogs
//
// Component:       `uk-modal`
//
// Sub-objects:     `uk-modal-page`
//                  `uk-modal-dialog`
//                  `uk-modal-header`
//                  `uk-modal-body`
//                  `uk-modal-footer`
//                  `uk-modal-title`
//                  `uk-modal-close`
//
// Adopted:         `uk-modal-close-default`
//                  `uk-modal-close-outside`
//                  `uk-modal-close-full`
//
// Modifiers:       `uk-modal-container`
//                  `uk-modal-full`
//
// States:          `uk-open`
//
// ========================================================================

// Variables
// ========================================================================

$modal-z-index: $global-z-index + 10 !default;
$modal-background: rgba(0, 0, 0, 0.6) !default;

$modal-padding-horizontal: 15px !default;
$modal-padding-horizontal-s: $global-gutter !default;
$modal-padding-horizontal-m: $global-medium-gutter !default;
$modal-padding-vertical: $modal-padding-horizontal !default;
$modal-padding-vertical-s: 50px !default;

$modal-dialog-width: 600px !default;
$modal-dialog-background: $global-background !default;

$modal-container-width: 1200px !default;

$modal-body-padding-horizontal: $global-gutter !default;
$modal-body-padding-vertical: $global-gutter !default;

$modal-header-padding-horizontal: $global-gutter !default;
$modal-header-padding-vertical: ($modal-header-padding-horizontal * 0.5) !default;
$modal-header-background: $global-muted-background !default;

$modal-footer-padding-horizontal: $global-gutter !default;
$modal-footer-padding-vertical: ($modal-footer-padding-horizontal * 0.5) !default;
$modal-footer-background: $global-muted-background !default;

$modal-title-font-size: $global-xlarge-font-size !default;
$modal-title-line-height: 1.3 !default;

$modal-close-position: $global-small-margin !default;
$modal-close-padding: 5px !default;

$modal-close-outside-position: 0px !default;
$modal-close-outside-translate: 100% !default;
$modal-close-outside-color: lighten($global-inverse-color, 20%) !default;
$modal-close-outside-hover-color: $global-inverse-color !default;

/* ========================================================================
   Component: Modal
 ========================================================================== */

/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */

.uk-modal {
    /* 1 */
    display: none;
    /* 2 */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $modal-z-index;
    /* 3 */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /* 4 */
    padding: $modal-padding-vertical $modal-padding-horizontal;
    padding-top: calc(env(safe-area-inset-top,0) + $modal-padding-vertical);
    padding-bottom: calc(env(safe-area-inset-bottom,0) + $modal-padding-vertical);    
    /* 5 */
    background: $modal-background;
    /* 6 */
    opacity: 0;
    transition: opacity 0.25s linear;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    @if (mixin-exists(hook-modal)) {
        @include hook-modal();
    }
}

/*
 * Open
 */

.uk-modal.uk-open {
    opacity: 1;
}

/* Page
 ========================================================================== */

/*
 * Prevent scrollbars
 */

.uk-modal-page {
    overflow: hidden;
}

/* Dialog
 ========================================================================== */

/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. Fix `max-width: 100%` not working in combination with flex and responsive images in IE11
 *    `!important` needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */

.uk-modal-dialog {
    /* 1 */
    position: relative;
    /* 2 */
    box-sizing: border-box;
    margin: 0 auto;
    width: $modal-dialog-width;
    /* 3 */
    max-width: unquote("calc(100% - 0.01px)") !important;
    /* 4 */
    background: $modal-dialog-background;
    /* 5 */
    opacity: 1;
    transition: 0.3s linear;
    transition-property: opacity, transform;

    @if (mixin-exists(hook-modal-dialog)) {
        @include hook-modal-dialog();
    }

    &.uk-position-center {
        max-width: calc(100% - 30px) !important;
    }
}

/*
 * Open
 */

/* Size modifier
 ========================================================================== */

/*
 * Container size
 * Take the same size as the Container component
 */

.uk-modal-container .uk-modal-dialog {
    width: $modal-container-width;
}

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */

/* 1 */

.uk-modal-full {
    padding: 0;
    background: none;
}

/* 2 */
.uk-modal-full .uk-modal-dialog {
    margin: 0;
    width: 100%;
    max-width: 600px;
    transform: translateY(0);
    @if (mixin-exists(hook-modal-full)) {
        @include hook-modal-full();
    }
}

/* Sections
 ========================================================================== */

.uk-modal-body {
    display: flow-root;
    padding: $modal-body-padding-vertical $modal-body-padding-horizontal;
    @if (mixin-exists(hook-modal-body)) {
        @include hook-modal-body();
    }
}

.uk-modal-header {
    display: flow-root;
    padding: $modal-header-padding-vertical $modal-header-padding-horizontal;
    background: $modal-header-background;
    @if (mixin-exists(hook-modal-header)) {
        @include hook-modal-header();
    }
}

.uk-modal-footer {
    display: flow-root;
    padding: $modal-footer-padding-vertical $modal-footer-padding-horizontal;
    background: $modal-footer-background;
    @if (mixin-exists(hook-modal-footer)) {
        @include hook-modal-footer();
    }
}

/* Title
 ========================================================================== */

.uk-modal-title {
    font-size: $modal-title-font-size;
    line-height: $modal-title-line-height;
    @if (mixin-exists(hook-modal-title)) {
        @include hook-modal-title();
    }
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */

[class*="uk-modal-close-"] {
    position: absolute;
    z-index: $modal-z-index;
    top: $modal-close-position;
    right: $modal-close-position;
    left: $modal-close-position;
    padding: $modal-close-padding;
    @if (mixin-exists(hook-modal-close)) {
        @include hook-modal-close();
    }
}

.uk-modal-close-top-right {
    right: unset !important;
    padding: $modal-close-padding * 2 !important;
}

.uk-modal-close-top-left {
    left: unset !important;
    padding: $modal-close-padding * 2 !important;
}

/*
 * Remove margin from adjacent element
 */

[class*="uk-modal-close-"]:first-child + * {
    margin-top: 0;
}

/*
 * Hover
 */

[class*="uk-modal-close-"]:hover {
    @if (mixin-exists(hook-modal-close-hover)) {
        @include hook-modal-close-hover();
    }
}

/*
 * Default
 */

.uk-modal-close-default {
    @if (mixin-exists(hook-modal-close-default)) {
        @include hook-modal-close-default();
    }
}

.uk-modal-close-default:hover {
    @if (mixin-exists(hook-modal-close-default-hover)) {
        @include hook-modal-close-default-hover();
    }
}

/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */

.uk-modal-close-outside {
    // top: $modal-close-outside-position;
    /* 1 */
    transform: translate(0, -$modal-close-outside-translate) !important;
    color: $modal-close-outside-color;
    @if (mixin-exists(hook-modal-close-outside)) {
        @include hook-modal-close-outside();
    }
}

.uk-modal-close-outside:hover {
    color: $modal-close-outside-hover-color;
    @if (mixin-exists(hook-modal-close-outside-hover)) {
        @include hook-modal-close-outside-hover();
    }
}

/*
 * Full
 */

.uk-modal-close-full {
    transform: translate(0, env(safe-area-inset-top));
    @if (mixin-exists(hook-modal-close-full)) {
        @include hook-modal-close-full();
    }
}

.uk-modal-close-full:hover {
    @if (mixin-exists(hook-modal-close-full-hover)) {
        @include hook-modal-close-full-hover();
    }
}

// Hooks
// ========================================================================

@if (mixin-exists(hook-modal-misc)) {
    @include hook-modal-misc();
}

// @mixin hook-modal(){}
// @mixin hook-modal-dialog(){}
// @mixin hook-modal-full(){}
// @mixin hook-modal-header(){}
// @mixin hook-modal-body(){}
// @mixin hook-modal-footer(){}
// @mixin hook-modal-title(){}
// @mixin hook-modal-close(){}
// @mixin hook-modal-close-hover(){}
// @mixin hook-modal-close-default(){}
// @mixin hook-modal-close-default-hover(){}
// @mixin hook-modal-close-outside(){}
// @mixin hook-modal-close-outside-hover(){}
// @mixin hook-modal-close-full(){}
// @mixin hook-modal-close-full-hover(){}
// @mixin hook-modal-misc(){}
