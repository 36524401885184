// Name:            Text
// Description:     Utilities for text
//
// Component:       `uk-text-*`
//
// ========================================================================

// Variables
// ========================================================================

$text-lead-font-size: $global-large-font-size !default;
$text-lead-line-height: 1.5 !default;
$text-lead-color: $global-emphasis-color !default;

$text-meta-font-size: $global-small-font-size !default;
$text-meta-line-height: 1.4 !default;
$text-meta-color: $global-muted-color !default;

$text-small-font-size: $global-small-font-size !default;
$text-small-line-height: 1.5 !default;

$text-large-font-size: $global-large-font-size !default;
$text-large-line-height: 1.5 !default;

$text-muted-color: $global-muted-color !default;
$text-emphasis-color: $global-emphasis-color !default;
$text-primary-color: $global-primary-background !default;
$text-secondary-color: $global-secondary-background !default;
$text-success-color: $global-success-background !default;
$text-success-color-alt: #546d67 !default;
$text-warning-color: $global-warning-background !default;
$text-danger-color: $global-danger-background !default;

$text-background-color: $global-primary-background !default;

/* ========================================================================
   Component: Text
 ========================================================================== */

/* Style modifiers
 ========================================================================== */

.uk-text-lead {
    font-size: $text-lead-font-size;
    line-height: $text-lead-line-height;
    color: $text-lead-color;
    @if (mixin-exists(hook-text-lead)) {
        @include hook-text-lead();
    }
}

.uk-text-meta {
    font-size: $text-meta-font-size;
    line-height: $text-meta-line-height;
    color: $text-meta-color;
    @if (mixin-exists(hook-text-meta)) {
        @include hook-text-meta();
    }
}

/* Size modifiers
 ========================================================================== */

.uk-text-xsmall {
    font-size: $text-xsmall-font-size;
    line-height: $text-small-line-height;
    @if (mixin-exists(hook-text-small)) {
        @include hook-text-small();
    }
}

.uk-text-small {
    font-size: $text-small-font-size;
    line-height: $text-small-line-height;
    @if (mixin-exists(hook-text-small)) {
        @include hook-text-small();
    }
}

.uk-text-large {
    font-size: $text-large-font-size;
    line-height: $text-large-line-height;
    @if (mixin-exists(hook-text-large)) {
        @include hook-text-large();
    }
}

.uk-text-default {
    font-size: $global-font-size;
    line-height: $global-line-height;
}

/* Weight modifier
 ========================================================================== */

.uk-text-light {
    font-weight: 300;
}
.uk-text-normal {
    font-weight: 400;
}
.uk-text-bold {
    font-weight: 700;
}

.uk-text-lighter {
    font-weight: lighter;
}
.uk-text-bolder {
    font-weight: bolder;
}

.uk-text-strikethrough {
    text-decoration: line-through;
}

/* Style modifier
 ========================================================================== */

.uk-text-italic {
    font-style: italic;
}

/* Transform modifier
 ========================================================================== */

.uk-text-capitalize {
    text-transform: capitalize !important;
}
.uk-text-uppercase {
    text-transform: uppercase !important;
}
.uk-text-lowercase {
    text-transform: lowercase !important;
}

/* Color modifiers
 ========================================================================== */

.uk-text-muted {
    color: $text-muted-color !important;
}
.uk-text-emphasis {
    color: $text-emphasis-color !important;
}
.uk-text-primary {
    color: $text-primary-color !important;
}
.uk-text-secondary {
    color: $text-secondary-color !important;
}
.uk-text-success {
    color: $text-success-color !important;
}
.uk-text-success-alt {
    color: $text-success-color-alt !important;
}
.uk-text-warning {
    color: $text-warning-color !important;
}
.uk-text-danger {
    color: $text-danger-color !important;
}

/* Background modifier
 ========================================================================== */

/*
 * 1. The background clips to the foreground text. Works in Chrome, Firefox, Safari, Edge and Opera
 *    Default color is set to transparent
 * 2. Container fits the text
 * 3. Fallback color for IE11
 */

.uk-text-background {
    /* 1 */
    -webkit-background-clip: text;
    /* 2 */
    display: inline-block;
    /* 3 */
    color: $text-background-color !important;
}

@supports (-webkit-background-clip: text) {
    .uk-text-background {
        background-color: $text-background-color;
        color: transparent !important;
        @if (mixin-exists(hook-text-background)) {
            @include hook-text-background();
        }
    }
}

/* Alignment modifiers
 ========================================================================== */

.uk-text-left {
    text-align: left !important;
}
.uk-text-right {
    text-align: right !important;
}
.uk-text-center {
    text-align: center !important;
}
.uk-text-justify {
    text-align: justify !important;
}

/* Phone landscape and bigger */
@media (min-width: $breakpoint-small) {
    .uk-text-left\@s {
        text-align: left !important;
    }
    .uk-text-right\@s {
        text-align: right !important;
    }
    .uk-text-center\@s {
        text-align: center !important;
    }
}

/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {
    .uk-text-left\@m {
        text-align: left !important;
    }
    .uk-text-right\@m {
        text-align: right !important;
    }
    .uk-text-center\@m {
        text-align: center !important;
    }
}

/* Desktop and bigger */
@media (min-width: $breakpoint-large) {
    .uk-text-left\@l {
        text-align: left !important;
    }
    .uk-text-right\@l {
        text-align: right !important;
    }
    .uk-text-center\@l {
        text-align: center !important;
    }
}

/* Large screen and bigger */
@media (min-width: $breakpoint-xlarge) {
    .uk-text-left\@xl {
        text-align: left !important;
    }
    .uk-text-right\@xl {
        text-align: right !important;
    }
    .uk-text-center\@xl {
        text-align: center !important;
    }
}

/*
 * Vertical
 */

.uk-text-top {
    vertical-align: top !important;
}
.uk-text-middle {
    vertical-align: middle !important;
}
.uk-text-bottom {
    vertical-align: bottom !important;
}
.uk-text-baseline {
    vertical-align: baseline !important;
}

/* Wrap modifiers
 ========================================================================== */

/*
 * Prevent text from wrapping onto multiple lines
 */

.uk-text-nowrap {
    white-space: nowrap;
}

/*
 * 1. Make sure a max-width is set after which truncation can occur
 * 2. Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 * 3. Fix for table cells
 */

.uk-text-truncate {
    /* 1 */
    max-width: 100%;
    /* 2 */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* 2 */
th.uk-text-truncate,
td.uk-text-truncate {
    max-width: 0;
}

/*
 * 1. Wrap long words onto the next line and break them if they are too long to fit
 * 2. Legacy `word-wrap` as fallback for `overflow-wrap`
 * 3. Fix `overflow-wrap` which doesn't work with table cells in Chrome, Opera, IE11 and Edge
 *    Must use `break-all` to support IE11 and Edge
 * Note: Not using `hyphens: auto;` because it hyphenates text even if not needed
 */

.uk-text-break {
    /* 1 */
    overflow-wrap: break-word;
    /* 2 */
    word-wrap: break-word;
}

/* 3 */
th.uk-text-break,
td.uk-text-break {
    word-break: break-all;
}

// Hooks
// ========================================================================

@if (mixin-exists(hook-text-misc)) {
    @include hook-text-misc();
}

// @mixin hook-text-lead(){}
// @mixin hook-text-meta(){}
// @mixin hook-text-small(){}
// @mixin hook-text-large(){}
// @mixin hook-text-background(){}
// @mixin hook-text-misc(){}

// Inverse
// ========================================================================

$inverse-text-lead-color: $inverse-global-color !default;
$inverse-text-meta-color: $inverse-global-muted-color !default;
$inverse-text-muted-color: $inverse-global-muted-color !default;
$inverse-text-emphasis-color: $inverse-global-emphasis-color !default;
$inverse-text-primary-color: $inverse-global-primary-background !default;
$inverse-text-secondary-color: $inverse-global-primary-background !default;

// @mixin hook-inverse-text-lead(){}
// @mixin hook-inverse-text-meta(){}
